import React from 'react';
import {
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonNote,
  IonButton,
  isPlatform,
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { Title, SmallText, Spacer, Sectiontitle } from '../../components/common';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import { createOrder } from '../../store/actions';
import { forwardTo } from '../../lib/utils';
import './index.css';
import { Capacitor } from '@capacitor/core';
import Stripe from '../../lib/stripe';

class Checkout extends React.Component {
  state = {
    selectedCard: null,
  };

  componentDidMount() {
    const { profile } = this.props;
    if (profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile.cardToken !== this.props.profile.cardToken) {
      this.changeSelectedPaymentCard(this.props.profile.cardToken);
    }
  }

  createOrder = (paymentType) => this.props.dispatch(createOrder(paymentType));

  drawPayButton = () => {
    const { __ } = this.props;
    let ret = null;
    if (Stripe.getStripeInstance() && Capacitor.platform !== 'web') {
      if (!isPlatform('ios')) {
        //android
        ret = (
          <IonButton expand="block" color="primary" onClick={() => this.createOrder('google')}>
            {__('Google Pay')}
          </IonButton>
        );
      } else {
        //ios
        ret = (
          <IonButton expand="block" color="primary" onClick={() => this.createOrder('apple')}>
            {__('Apple Pay')}
          </IonButton>
        );
      }
    }
    return ret;
  };

  changeSelectedPaymentCard = (cardId) =>
    this.setState({ selectedCard: cardId }, () => {
      Basket.changeSelectedCard(cardId);
    });

  render() {
    const { __, cards } = this.props;
    const buttonCondition = Basket.getTotal() > 0;
    return (
      <Loading transparent>
        <Layout color="white" headerTitle={__('Checkout')}>
          <div className="flex-row-wrapper absolute-content">
            <Title>{__('Checkout')}</Title>
            <SmallText color="gray">{__('Use saved payment card')}</SmallText>
            <Spacer size={1} />
            <div className="scrollable-y">
              <IonList lines="full">
                <IonRadioGroup
                  onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                  value={this.state.selectedCard}
                >
                  {cards.map((card) => {
                    const { id, last4, brand, exp_month, exp_year, name } = card;

                    return (
                      <IonItem key={id}>
                        <div tabIndex="-1"></div>
                        <IonRadio
                          color="primary"
                          slot="start"
                          value={id}
                          onIonSelect={() => {
                            this.setState({ selectedCard: id }, () => {
                              Basket.changeSelectedCard(id);
                            });
                          }}
                        />
                        <IonLabel className="ion-text-wrap">
                          <Sectiontitle className="single-item">{name}</Sectiontitle>
                          <Sectiontitle className="no-margin">**** **** **** {last4}</Sectiontitle>
                          <IonNote>
                            {__(brand)} - {exp_month}/{exp_year}
                          </IonNote>
                        </IonLabel>
                      </IonItem>
                    );
                  })}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="flex-min">
              <IonItem>
                <IonButton
                  fill="clear"
                  className="link link-centered"
                  onClick={() => forwardTo('/card-add', { addCardFromCheckout: true })}
                >
                  {__('Add Payment Card')}
                </IonButton>
              </IonItem>
              <IonButton
                disabled={buttonCondition ? false : cards.length === 0 ? true : true}
                onClick={this.createOrder}
                expand="block"
              >
                {__('Pay')}
              </IonButton>
              {/* { this.drawPayButton() } */}
              <div className="centered">
                <IonButton
                  color="gray"
                  fill="clear"
                  className="link"
                  onClick={() => forwardTo('/order-summary')}
                >
                  {__('Cancel')}
                </IonButton>
              </div>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    cards: store.orders.cards || [],
    profile: store.profile.profile || {},
  };
};

export default connect(mapStateToProps)(withTranslation(Checkout));
