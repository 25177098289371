import { GET_RESTAURANTS, GET_REWARDS, GET_IKENTOO_MENU, BEFORE_CLOSE_TIME_PICKER, BEFORE_SHOW_TIME_PICKER, SAVE_ALLERGENS, GET_RESTAURANTS_SNOOZED_DATA } from './constants'

export const getRestaurants = () => ({ type: GET_RESTAURANTS })

export const getRewards = () => ({ type: GET_REWARDS })

export const getIkentooMenu= (menuId, businessLocationId, redirect = true) => ({ type: GET_IKENTOO_MENU, menuId, businessLocationId, redirect })

export const beforeShowTimePicker = () => ({ type: BEFORE_SHOW_TIME_PICKER })

export const beforeCloseTimePicker = () => ({ type: BEFORE_CLOSE_TIME_PICKER })

export const getRestaurantSnoozeData = () => ({ type: GET_RESTAURANTS_SNOOZED_DATA })

export const saveAllergens = (data) => ({ type: SAVE_ALLERGENS, data })