import React from 'react';
import { IonButton, IonItem, IonInput, isPlatform, IonText, IonContent } from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import StaticHeader from '../../components/staticHeader/loyaltyStaticHeader';
import Clickup from '../../components/clickup';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, SmallText, FieldError } from '../../components/common';
import { validateForm, isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { sendVoucherCode } from '../../store/actions';
import QRCode from 'qrcode-react';
import moment from '../../lib/moment';
import './index.css';
import EmptyVoucher from '../../assets/images/logo-main.jpg';
import { GET_VOUCHERS } from '../../store/constants';

export const VoucherItem = ({ reward, id, className, __, action }) => {
  return (
    <div onClick={() => action(id)} className={'voucher' + (className ? ' ' + className : '')}>
      <img src={reward.image} />
    </div>
  );
};

class Vouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    this.props.dispatch({ type: GET_VOUCHERS });
    if (
      isDefined(this.props.location.state) &&
      isDefined(this.props.location.state.openVoucherModal)
    ) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
      //console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
    }
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode };
      this.clearFields();
      dispatch(sendVoucherCode(data));
    }
  };

  render() {
    const { __, qr_code, vouchers } = this.props;
    const { voucherCode, selectedVoucher } = this.state;
    const clickupContent = (
      <Clickup label={__('Redeem Promo Code')} height="320">
        <div className="absolute-wrapper flex-row-wrapper">
          <div className="padded no-padding-bottom scrollable-y">
            <p className="no-margin-top">
              {__('Enter a valid voucher code below to receive your reward')}
            </p>
            <div>
              <Spacer size={1} />
              <IonItem className="voucher-code">
                <IonInput
                  value={voucherCode}
                  onIonChange={(e) => this.setState({ voucherCode: e.target.value })}
                  placeholder={__('Voucher code')}
                />
                <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
              </IonItem>
            </div>
          </div>
          <div className="padded no-padding-top flex-min">
            <IonButton
              disabled={voucherCode === ''}
              color="primary"
              expand="block"
              onClick={this.sendCode}
            >
              {__('Submit')}
            </IonButton>
          </div>
        </div>
      </Clickup>
    );
    return (
      <Loading transparent>
        <IonContent className="rewards-content">
          <StaticHeader />
          <div className="voucher-content-wrapper">
            <div className="absolute-content rewards-background"></div>
            {vouchers && vouchers.length > 0 && (
              <>
                <Title className="voucher-title">{__('My Rewards')}</Title>
                <Spacer />
              </>
            )}
            {!vouchers || (vouchers && vouchers.length === 0) ? (
              <div className="no-voucher">
                <div className="no-voucher-image">
                  <img src={EmptyVoucher} alt="no-voucher" width="250px" className="empty-logo" />
                  <Spacer />
                  <Title className="voucher-title">{__('Your vouchers will appear here.')}</Title>
                </div>
              </div>
            ) : null}
            {vouchers && vouchers.length > 0 && (
              <div className="voucher-items">
                {vouchers &&
                  vouchers.map((voucher, index) => {
                    return (
                      <VoucherItem
                        key={'vc-' + index}
                        {...voucher}
                        __={__}
                        action={this.viewVoucher}
                      />
                    );
                  })}
              </div>
            )}
          </div>
          {!isPlatform('ios') ? clickupContent : null}
        </IonContent>
        {isPlatform('ios') ? clickupContent : null}
        <Modal
          className="voucher-modal"
          isOpen={selectedVoucher !== null}
          onDidDismiss={() => this.setState({ selectedVoucher: null })}
          title={__('Voucher Info')}
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div className="centered">
              <div
                className="voucher-modal-image"
                style={{ backgroundImage: `url(${selectedVoucher.reward.image})` }}
              ></div>
              <div className="voucher-modal-content">
                {selectedVoucher.reward.name ? (
                  <>
                    <NormalText className="uppercase">{selectedVoucher.reward.name}</NormalText>
                    <Spacer />
                  </>
                ) : null}
                {selectedVoucher.reward.small_print ? (
                  <div>
                    <NormalText>{selectedVoucher.reward.small_print}</NormalText>
                  </div>
                ) : null}
                {selectedVoucher.reward.expiry_date ? (
                  <div>
                    <NormalText>
                      {__('Expires:')}{' '}
                      {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
                    </NormalText>
                  </div>
                ) : null}
                <div>
                  <NormalText>{__('Terms & Conditions')}</NormalText>
                </div>
                <Spacer />
                {selectedVoucher.reward.description ? (
                  <div>
                    <SmallText>{__(selectedVoucher.reward.description)}</SmallText>
                  </div>
                ) : null}
                <>
                  <Spacer />
                  {isDefined(qr_code) ? (
                    <div className="qr-holder">
                      <QRCode value={qr_code} size={200} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                  <Spacer />
                </>
              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  return {
    vouchers: state.profile.vouchers,
    qr_code: state.profile.profile.qr_code,
  };
};

export default connect(stateToProps)(withTranslation(Vouchers));
