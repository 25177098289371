import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IonCheckbox, IonTextarea, IonItem, IonList, IonLabel, IonRadio, IonRadioGroup, IonButton, IonIcon, IonAlert } from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import Layout from '../../components/layout'
import Incrementer from '../../components/incrementer'
import Pullup from '../../components/pullup'
import { SmallText, Sectiontitle, Spacer } from '../../components/common'
import { withTranslation } from '../../lib/translate'
import Basket from '../../lib/basket'
import { makeKey, isDefined, deepCopy, goBack } from '../../lib/utils'
import { getLocale } from '../../lib/utils'
import { PillGroup } from '../../components/pill'
import { saveAllergens } from '../../store/restaurants/actions'
import { getConfig } from '../../appConfig'
import './index.css'

const { formatPrice, _calculateItemPrice, validateItem, addToBasket, getProductName, getProductDescription, isProductEnabled, isChoicesGroupValid } = Basket

const noImage = () => <div className="item-details-background"></div>

export class itemDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {
			quantity: 1,
			price: 0,
			selectedChoices: [],
			validationErrors: [],
			instructions: '',
			showValidationAlert: false,
			allergensCodes: []
		}
	}

	componentDidMount() {
		const { location, profile, allergens } = this.props
		let quantity = this.state.quantity
		const item = location.state
		let selectedChoices = []
		let validationErrors = []

		if (item.menuDealGroups && item.menuDealGroups.length > 0) {
			selectedChoices = Array(item.menuDealGroups.length).fill([])
			validationErrors = Array(item.menuDealGroups.length).fill(null)
		}
		let price = _calculateItemPrice({ item, quantity })
		this.setState({ price, selectedChoices, validationErrors })
		let locale = getLocale(profile)
		if (locale === 'fr') {
			locale = 'fr_FR'
		}
		let allergensCodes = item && item.itemRichData && item.itemRichData.allergenCodes && item.itemRichData.allergenCodes.length > 0 ? item.itemRichData.allergenCodes : []
		let newArr = []
		allergensCodes.map((allergenCode) => {
			(allergens && allergens.data ? allergens.data : []).find((allergen) => {
				if (allergen.code === allergenCode) {
					allergen.translations.find((data) => {
						if (data.locale === locale) {
							newArr.push(data.text)
						}
					})
				}
			})
		})
		let allergensData = [
			{ allergens: newArr },
			{	sku: item.sku }
		]
		this.props.dispatch(saveAllergens(
			allergensData
		))
		this.setState({ price, selectedChoices, validationErrors, allergensCodes: newArr })
	}

	onIncrementerUpdate = newQuantity => {
		this.setState({ quantity: newQuantity, price: _calculateItemPrice({
			item: this.props.location.state,
			quantity: newQuantity,
			selectedChoices: this.state.selectedChoices
		}) })
	}

	handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
		const item = this.props.location.state
		const { quantity, selectedChoices } = this.state
		const allGroups = item.menuDealGroups ? item.menuDealGroups : []
		const selectedGroup = allGroups[groupIndex]

		if (selectedGroup) {
			const selectedChoice = selectedGroup.items[choiceIndex]
			let updatedSelectedChoices = deepCopy(selectedChoices)
			if (multiSelectionPermitted) {
				//checkbox
				if (event.target.checked) {
					updatedSelectedChoices[groupIndex].push(selectedChoice)
				} else {
					updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(i => i.sku !== selectedChoice.sku)
				}
			} else {
				//radio
				if (!selectedChoice) {
					updatedSelectedChoices[groupIndex] = []
				} else {
					updatedSelectedChoices[groupIndex] = [ selectedChoice ]
				}
			}

			this.setState({ selectedChoices: updatedSelectedChoices }, () => {
				const { selectedChoices } = this.state
				//recalculate item price on every menu deal choice change
				this.setState({ price: _calculateItemPrice({ item, quantity, selectedChoices }) }, () => {
					const validationErrors = validateItem(this.constructBasketItem())
					this.setState({ validationErrors: validationErrors.errors })
				})
			})
		}
	}

	drawGroupChoices = (choices = [], multiSelectionPermitted, groupIndex) => {
		const { selectedChoices } = this.state
		const { profile, __ } = this.props
		const allChoices = choices.map((item, choiceIndex) => {
			const { sku, productPrice } = item
			const isChecked = !!(selectedChoices[groupIndex] || []).find(i => {
				return i.sku === sku
			})
			if (item.sku === undefined || !isProductEnabled(item)) {
				return <span key={item.sku + '_disabled'}/>
			}
			return (
				<IonItem lines="none" key={ makeKey(choiceIndex, sku, groupIndex) }>
					<div tabIndex="-1"></div>
					{ multiSelectionPermitted ?
						<IonCheckbox color="primary" slot="start" checked={ isChecked } onIonChange={ event => {
							this.handleInputChange(groupIndex, choiceIndex, multiSelectionPermitted, event)
						}}/> :
						<IonRadio slot="start" className="details-radio" color="primary" value={ choiceIndex } checked={ isChecked } />
					}
					<IonLabel className="ion-text-wrap">
						<Sectiontitle className="single-item">{ __(getProductName(item, profile)) }</Sectiontitle>
						{getProductDescription(item, profile) ? <SmallText className="no-margin">{ getProductDescription(item, profile).replace(/(<([^>]+)>)/gi, '') }</SmallText> : null }
					</IonLabel>
					<p>{ formatPrice(productPrice) }</p>
				</IonItem>
			)
		})
		if (multiSelectionPermitted) {
			return allChoices
		} else {
			//radio
			return (
				<IonRadioGroup allowEmptySelection={ true } onIonChange={ event => {
					this.handleInputChange(groupIndex, event.target.value, multiSelectionPermitted, event)
				}}>{ allChoices }</IonRadioGroup>
			)
		}
	}

	drawGroupLabel = (label, groupIndex) => {
		const { validationErrors } = this.state
		const { __ } = this.props
		return <>
			<IonItem>
				<div tabIndex="-1" className="sectiontitle">{ label }</div>
			</IonItem>
			{ validationErrors[groupIndex] ? <div className="field-error">{ __(validationErrors[groupIndex]) }</div> : null }
		</>
	}

	drawMenuDealGroups = (menuGroupItem, index) => {
		// multiSelectionPermitted = true  --> only one item must be selected
		const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted
		if (isChoicesGroupValid(menuGroupItem)) {
			if (isDefined(multiSelectionPermitted)) {
				return (
					<div key={ index }>
						{ this.drawGroupLabel(menuGroupItem.description, index) }
						{ this.drawGroupChoices(menuGroupItem.items, multiSelectionPermitted, index) }
					</div>
				)
			} else {
				return this.drawGroupLabel(menuGroupItem.description)
			}
		}
	}

	constructBasketItem = () => {
		const { location } = this.props
		const { quantity, selectedChoices, instructions } = this.state
		const item = location.state

		return {
			item,
			quantity,
			selectedChoices,
			instructions
		}
	}

	addToOrder = () => {
		const newBasketItem = this.constructBasketItem()
		const validationErrors = validateItem(newBasketItem)
		if (validationErrors.errorCount > 0) {
			this.setState({ validationErrors: validationErrors.errors }, () => {
				this.setShowValidationAlert(true)
			})
		} else {
			addToBasket(newBasketItem)
			// forwardTo('/order-summary', { fromItemDetails: true })
			goBack()
		}
	}

	instructionsChange = event => this.setState({ instructions: event.target.value })

	setShowValidationAlert = flag => this.setState({ showValidationAlert: flag })

	render() {
		const { __, location, history, profile } = this.props
		const { quantity, price, showValidationAlert, allergensCodes } = this.state
		const item = location.state
		const image = item.itemRichData && item.itemRichData.squareImageUrl ? item.itemRichData.squareImageUrl : ''
		const menuDealGroups = item.menuDealGroups ? item.menuDealGroups : []
		const isAddToOrderBtnValid = validateItem(this.constructBasketItem()).errorCount === 0
		return (
			<Layout headerTitle={ __('Item Details') } scrollY={ false } noPadding contentClassName="item-details-wrapper" hideSecondToolbar={ true }>
				{image && image !== '' ?
					<div className="item-details-image" style={{ backgroundImage: 'url(' + image + ')' }}/> :
					noImage()
				}

				<Pullup className="item-details-card" offsetTop={ 55 } top={ 75 } offsetBottom={ window && window.innerHeight ? Math.round(window.innerHeight /2) + 20 : 220 } contentOffsetBottom={ 120 }
					header={(
						<div className="item-details-card-header flex-col-wrapper">
							<div className="title ellipsis">{ __(getProductName(item, profile)) }</div>
							<div className="item-details-card-price">{ formatPrice(item.productPrice) }</div>
						</div>)}>
					<div className="item-details-card-content">
						<IonList className="item-details-card-list">
							{/* <IonItem lines="none">
								<Sectiontitle>{ __('Description') }</Sectiontitle>
							</IonItem> */}
							<IonItem>
								<SmallText className="item-details-card-description" dangerouslySetInnerHTML={{ __html: getProductDescription(item, profile) }}></SmallText>
							</IonItem>
							{allergensCodes.length > 0 ?
								<>
									<Spacer size="1"/>
									<Sectiontitle>{ __('Allergens') }</Sectiontitle>
									<IonItem>
										<div tabIndex="-1"></div>
										<PillGroup items={ allergensCodes } />
									</IonItem>
								</>
								: null}
							{ menuDealGroups.map(this.drawMenuDealGroups) }
							{ getConfig().flags.specialInstructions.isDisplayed ?
								<>
									<IonItem lines="none">
										<div tabIndex="-1"></div>
										<Sectiontitle>{ __('Special Instructions') } <span className="normal-text">{ __('Optional') }</span></Sectiontitle>
									</IonItem>
									<IonItem>
										<IonTextarea onIonChange={ this.instructionsChange } rows={ 1 } placeholder={ __(getConfig().flags.specialInstructions.placeholder) }></IonTextarea>
									</IonItem>
								</> : null
							}
						</IonList>
					</div>
				</Pullup>

				<div className="item-details-actions">
					<Incrementer allowNegative={ false } quantity={ quantity } onUpdate= { this.onIncrementerUpdate }/>
					<IonButton disabled={ quantity === 0 } className={'item-details-add-to-order ' + (isAddToOrderBtnValid ? '' : 'greyed')} size="full" shape="round" onClick={ this.addToOrder }>
						{ __('Add to Order') } { price ? '-' : '' } <span className="item-details-add-to-order-price">{ price }</span>
					</IonButton>
				</div>
				<div className="item-details-back">
					<IonButton className="solo-button" color="white" onClick={() => history.goBack() }>
						<IonIcon slot="icon-only" icon={ arrowBack }/>
					</IonButton>
				</div>
				<IonAlert
					isOpen={ showValidationAlert }
					onDidDismiss={() => this.setShowValidationAlert(false)}
					header={ __('Validation') }
					message={ __('Please check any required options') }
					buttons={[{ text: __('OK'), role: 'cancel', cssClass: 'secondary' }]}
				/>
			</Layout>
		)
	}
}

const mapStateToProps = store => {
	return {
		profile: store.profile.profile,
		restaurantsUpdated: store.restaurants.restaurantsUpdated,
		basketUpdated: store.orders.basketUpdated,
		allergens: store.restaurants.allergens
	}
}

export default connect(mapStateToProps)(withTranslation(itemDetails))
