import React from 'react'
import Loading from '../../components/spinner'
import { withTranslation } from '../../lib/translate'
import Layout from '../../components/layout'
import { Title, SmallText, Spacer } from '../../components/common'
import Box, { BoxHeader } from '../../components/box'
import OrderContent from '../../components/orderContent'
import { createNewBasketInstance } from '../../lib/basket'
import { forwardTo } from '../../lib/utils'
import { connect } from 'react-redux'
import AmountPaid from '../../components/amountPaid'
import './index.css'

class HistoryDetails extends React.Component {
	backHandler = () => {
		forwardTo('/dashboard')
		forwardTo('/history', { tab: 'order' })
	}

	formatPaymentString = (str) => {
		let splitedStr = str.split(' ').splice(2).join(' ')
		return splitedStr
	}

	render () {
		const { __, location, cards } = this.props
		const order = location.state.order
		const basketInstance = createNewBasketInstance()
		basketInstance.recreateOrder(order)

		return (
			<Loading>
				<Layout headerTitle={ __('History') } backHandler = { this.backHandler } scrollY={ false } >
					<div className="absolute-content history-details">
						<div className="scrollable-y">
							<Title>{ __('History') }</Title>
							<SmallText color="gray">{ __(basketInstance.getOrderType()) } { __('Order') }  #{ order.id }</SmallText>
							<Spacer size={ 3 }/>
							<Box>
								<BoxHeader>
									<p className="light-text">
										<SmallText tag="strong">{ __('Order Location') }:</SmallText> <SmallText>{ order.restaurant_name }</SmallText><br />
										<SmallText tag="strong">{ __('Order Time') }:</SmallText> <SmallText>{ basketInstance.formatOrderTime() }</SmallText><br />
										<SmallText tag="strong">{ __('Payment Method') }:</SmallText> <SmallText>{ this.formatPaymentString(basketInstance.formatPaymentMethod(cards, __)) }</SmallText>
									</p>
								</BoxHeader>
								<OrderContent basketInstance={ basketInstance } type="orderHistory"/>
								<AmountPaid order={ order } cards={ cards } />
							</Box>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const mapStateToProps = store => {
	const orders = store.orders
	return {
		cards: orders.cards || []
	}
}

export default connect(mapStateToProps)(withTranslation(HistoryDetails))
