import React, { Component } from 'react';
import { IonText } from '@ionic/react';
import { withTranslation } from '../../lib/translate';
import { Title, Spacer } from '../../components/common';

export class Points extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedIndex: null,
    };
  }

  toggleModal = (val, index) => {
    this.setState({ isOpen: val, selectedIndex: index });
  };

  render() {
    const { __, available_balance } = this.props;

    return (
      <div className="absolute-content points-tab">
        <div className="points-area">
          <div className="balance-target">
            <div className="balance">
              <IonText color="white" className="label-name">
                {__('BALANCE')}
              </IonText>
              <IonText color="white" className="label-number">
                {available_balance}
              </IonText>
              <IonText color="white" className="label-name">
                {__('POINTS')}
              </IonText>
            </div>
            <div className="dash-area">
              <div className="dash-line" />
            </div>
            <div className="target">
              <IonText color="white" className="label-name">
                {__('TARGET')}
              </IonText>
              <IonText color="white" className="label-number">
                100
              </IonText>
              <IonText color="white" className="label-name">
                {__('POINTS')}
              </IonText>
            </div>
          </div>
          <div className="points-gif" />
        </div>
      </div>
    );
  }
}

export default withTranslation(Points);
