import React from 'react';
import QRCode from 'qrcode-react';
import { withTranslation } from '../../lib/translate';
import { isDefined, validateProfileData } from '../../lib/utils';
import { NormalText, SmallText, Spacer, Title } from '../../components/common';

const Scan = ({ __, qr_code, profile }) => {
  const valid = validateProfileData(profile).isValid;
  return (
    <>
      <div className="loyalty-content scan scrollable-y">
        <Spacer size={2} />
        <div className="name-content">
          <Title className="name-content-header">
            {profile.first_name} {profile.last_name}
          </Title>
          <Spacer size={1} />
          <NormalText className="name-content-header">
            {__('Collect points every time you scan and earn Rewards')}
          </NormalText>
        </div>
        <Spacer size={3} />
        {isDefined(qr_code) ? (
          <div className="qr-holder">
            <div className="qr-code">
              <QRCode value={qr_code} size={150} />
            </div>
          </div>
        ) : (
          <div className="noQrCode">
            <h5>{__('NO QR CODE')}</h5>
          </div>
        )}
        {/* <ValidateButton />
        {!valid && getConfig().appType.hasEmailValidationEnabled ? (
          <div className="verified-content">
            <SmallText color="grey">
              {__('You can earn, but not redeem points until your account is verified')}
            </SmallText>
          </div>
        ) : null} */}
      </div>
    </>
  );
};

export default withTranslation(Scan);
