import { take, call, put, select } from 'redux-saga/effects'
import api from '../../lib/api'
import { loading } from '../common/sagas'
import { forwardTo, isDefined, sortLocations, deepCopy } from '../../lib/utils'
import { SET_RESTAURANT_PROP, GET_RESTAURANTS, GET_REWARDS, GET_IKENTOO_MENU, GET_RESTAURANTS_SNOOZED_DATA } from './constants'
import { LOCATION } from '../common/constants'
import { showToast } from '../actions'
import { translateSaga } from '../common/sagas'
import Basket from '../../lib/basket'

/* get Restaurants Saga */
export const getRestaurantsFlow = function* () {
	while (true) {
		yield take(GET_RESTAURANTS)
		//get restaurants
		yield call(loading, function *() {
			const restaurants = yield call(api.getRestaurants)

			const store = yield select()
			const myLocation = store.common.myLocation
			const sortRestaurants = yield call(sortLocations, restaurants, myLocation)

			yield put({ type: SET_RESTAURANT_PROP, key: 'restaurants', value: sortRestaurants })
			yield put({ type: LOCATION })
		})
	}
}

/* get Restaurants Saga */
export const getRewardsFlow = function* () {
	while (true) {
		yield take(GET_REWARDS)
		//get restaurants
		yield call(loading, function *() {
			let rewards = yield call(api.getRewards)
			rewards = rewards.sort((a, b) => {
				if (a.stamps_required < b.stamps_required) {return -1}
				if (a.stamps_required > b.stamps_required ) {return 1}
				return 0
			})
			yield put({ type: SET_RESTAURANT_PROP, key: 'rewards', value: rewards })
		})
	}
}

const filterIkentooMenuItems = items => {
	let filteredItems = []
	items.forEach(item => {
		if (!item.menuEntry) {
			// break recursion when arrive to the product
			if (item.sku && Basket.isProductEnabled(item)) {
				filteredItems.push(item)
				return [item]
			} else {
				return []
			}
		} else {
			const len = (item.menuEntry || []).length
			if (len > 0) {
				const newFilteredItems = filterIkentooMenuItems(item.menuEntry)
				if (newFilteredItems.length > 0) {
					item.menuEntry = newFilteredItems
					filteredItems.push(item)
				}
			}
		}
	})

	return filteredItems
}

const filterMenu = menu => {
	if (menu && menu.menuEntryGroups) {
		menu.menuEntryGroups = filterIkentooMenuItems(menu.menuEntryGroups)
		return menu
	}
	return menu
}

/* get ikentoo menu Saga */
export const getIkentooMenuFlow = function* () {
	while (true) {
		const { menuId, businessLocationId, redirect } = yield take(GET_IKENTOO_MENU)
		//get restaurants
		yield call(loading, function *() {
			let ikentooMenu = yield call(api.getIkenooMenu, menuId, businessLocationId)
			if (isDefined(ikentooMenu.error)) {
				yield put(showToast(yield call(translateSaga, 'Get restaurant menu error.'), 'warning'))
				ikentooMenu = {}
			}
			yield put({ type: SET_RESTAURANT_PROP, key: 'ikentooMenu_original', value: deepCopy(ikentooMenu) })
			ikentooMenu = yield call(filterMenu, deepCopy(ikentooMenu))
			yield put({ type: SET_RESTAURANT_PROP, key: 'ikentooMenu', value: ikentooMenu })
			if (redirect) {
				yield call(forwardTo, '/order')
			}
		})
	}
}

/* get ikentoo snoozed menu Saga */
export const getRestaurantSnoozeDataFlow = function* () {
	while (true) {
		yield take(GET_RESTAURANTS_SNOOZED_DATA)
		//get restaurants snoozed data
		const restaurantsSnoozedData = yield call(api.getRestaurantSnoozeData)
		let restaurants = yield select(state => state.restaurants.restaurants)
		restaurantsSnoozedData.forEach(snoozedRestaurant => {
			restaurants = restaurants.map(restaurant => {
				if (restaurant.business_location_id === snoozedRestaurant.business_location_id) {
					const newRestaurant = { ...restaurant, disabled_skus: snoozedRestaurant.data.disabled_skus, snoozed_skus: snoozedRestaurant.data.snoozed_skus }
					if (Basket.getRestaurant() && Basket.getRestaurant().business_location_id === snoozedRestaurant.business_location_id) {
						Basket.setRestaurant(newRestaurant)
					}
					return newRestaurant
				}
				return restaurant
			})
		})
		yield put({ type: SET_RESTAURANT_PROP, key: 'restaurants', value: restaurants })
		let ikentooMenu = yield select(state => state.restaurants.ikentooMenu_original)
		if (ikentooMenu) {
			ikentooMenu = yield call(filterMenu, deepCopy(ikentooMenu))
			yield put({ type: SET_RESTAURANT_PROP, key: 'ikentooMenu', value: ikentooMenu })
			yield put({ type: SET_RESTAURANT_PROP, key: 'restaurantsUpdated', value: Date.now() })
		}
	}
}
