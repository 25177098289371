import React from 'react';
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonInput } from '@ionic/react';
import { starOutline } from 'ionicons/icons';
import Loading from '../../components/spinner';
import { pencilOutline } from 'ionicons/icons';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, SmallText, Sectiontitle, Spacer } from '../../components/common';
import './index.css';
import Basket from '../../lib/basket';
//import { makeKey } from '../../lib/utils'
import ValidateButton from '../../components/validateButton';
import { getConfig } from '../../appConfig';
import Modal from '../../components/modal';
import Incrementer from '../../components/incrementer';
import { connect } from 'react-redux';
import OrderContent from '../../components/orderContent';
import { forwardTo, validateProfileData, goBack, go } from '../../lib/utils';
import { PillGroup } from '../../components/pill';
import { setModal } from '../../store/actions';

const {
  getRestauranName,
  getOrderDate,
  getOrderTime,
  changeItemQuantity,
  itemsCount,
  setMobile,
  getMobile,
  getItems,
} = Basket;

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      quantityModal: null,
      allergenModalOpen: false,
      mobile: getMobile() || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    setMobile(val);
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else {
      goBack();
    }
  };

  handleSubmit = () => {
    if (this.props.profile.mobile && this.props.profile.first_name) {
      // console.log('---' )
      forwardTo('/checkout');
      setMobile(this.props.profile.mobile);
    } else {
      forwardTo('/contact-details');
    }
  };

  handleOrderItemClick = (item, index) => {
    this.setState({ quantityModal: { item, index } });
  };

  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };

  render() {
    const { __, profile, auth, dispatch, itemAllergens } = this.props;
    const { quantityModal, allergenModalOpen, mobile } = this.state;
    const valid = validateProfileData(profile).isValid;
    const appliedVouchers = Basket.getAppliedVocuher();

    return (
      <Loading>
        <Layout headerTitle={__('Order Summary')} backHandler={this.backHandler}>
          <div className="absolute-content flex-row-wrapper">
            <div className="scrollable-y checkout">
              <Title>{__('Order Summary')}</Title>
              <SmallText color="gray">
                {__('Ordering for')} <strong>{getOrderDate()}</strong> @ {getOrderTime()}
                <br />
                {__('from')} <strong>{getRestauranName()}</strong>
              </SmallText>
              <IonItem lines="full">
                <IonLabel slot="start" className="ion-text-wrap">
                  <Sectiontitle className="no-margin">{__('Contact number')}</Sectiontitle>
                </IonLabel>
                <IonInput
                  onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                  clearInput
                  required={true}
                  type="tel"
                  pattern="tel"
                  inputmode="tel"
                  value={mobile}
                ></IonInput>
                <IonButtons slot="end" className="no-margin">
                  <IonButton
                    color="gray"
                    /*disabled={ value === '' }*/ fill="clear"
                    size="small"
                    /*onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)}*/ onMouseUp={() =>
                      this.togglePass(false)
                    }
                  >
                    <IonIcon slot="icon-only" size="small" icon={pencilOutline} />
                  </IonButton>
                </IonButtons>
              </IonItem>
              <div className="righted">
                <IonButton fill="clear" className="link" onClick={() => forwardTo('/order')}>
                  <strong>+ {__('Add Items')}</strong>
                </IonButton>
              </div>
              {itemsCount() > 0 ? (
                <OrderContent
                  showAddItems={true}
                  handleOrderItemClick={this.handleOrderItemClick.bind(this)}
                  handleApplyModal={this.handleApplyModal}
                />
              ) : (
                <IonItem lines="none">
                  <div
                    tabIndex="-1"
                    className="sectiontitle"
                    style={{ width: '100vh', textAlign: 'center' }}
                  >
                    {__('No items')}
                  </div>
                </IonItem>
              )}
              <div className="validate-content">
                <ValidateButton />
                {!valid && getConfig().appType.hasEmailValidationEnabled ? (
                  <div className="verified-content">
                    <SmallText color="grey">
                      {__('You can earn, but not redeem points until your account is verified')}
                    </SmallText>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex-min">
              <IonButton
                fill="clear"
                expand="block"
                className="link underlined"
                color="dark"
                onClick={() => this.setState({ allergenModalOpen: true })}
              >
                {__('View allergen information')}
              </IonButton>
              <IonButton
                className={`redeem-points-btn ${itemsCount() === 0 || !valid ? 'disabled' : ''}`}
                onClick={() => {
                  if (itemsCount() === 0 || !valid) {
                    dispatch(setModal('isVerfiedModalOpen', true));
                  } else {
                    forwardTo('/apply-vouchers');
                  }
                }}
                expand="block"
                fill="outline"
                color="dark"
              >
                {appliedVouchers && appliedVouchers.length > 0
                  ? __('Replace Voucher')
                  : __('Apply Vouchers')}
              </IonButton>
              <IonButton disabled={itemsCount() === 0} onClick={this.handleSubmit} expand="block">
                {__('Checkout')}
              </IonButton>
            </div>
          </div>

          <Modal
            title={__('Change quantity')}
            action={this.updateItemQuantity}
            actionLabel={__('Change')}
            isOpen={!!quantityModal}
            onDidDismiss={() => this.setState({ quantityModal: null })}
          >
            {quantityModal && quantityModal.item ? (
              <Incrementer
                allowNegative={false}
                quantity={quantityModal.item.quantity}
                onUpdate={this.onIncrementerUpdate}
              />
            ) : null}
          </Modal>
          <Modal
            cssClass="allergen-modal"
            isOpen={allergenModalOpen}
            onDidDismiss={() => this.setState({ allergenModalOpen: false })}
          >
            <Title className="centered">{__('Allergen Information')}</Title>
            <Spacer />
            {getItems().map((item, index) => {
              const allergens = itemAllergens || [];
              let data = allergens.map((allergen) => {
                if (allergen[1].sku === item.item.sku) {
                  return (
                    <div key={'allergen-' + index}>
                      <Sectiontitle>
                        {item.quantity}x {item.item.productName}
                      </Sectiontitle>
                      <PillGroup items={allergen[0].allergens} borderColor="primary" />
                      <Spacer />
                    </div>
                  );
                }
              });
              return data;
            })}
          </Modal>
        </Layout>
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    basketUpdated: store.orders.basketUpdated,
    profile: store.profile.profile,
    itemAllergens: store.restaurants.itemAllergens,
  };
};

export default connect(mapStateToProps)(withTranslation(OrderSummary));
