import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonText } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import './index.css';

class Rewards extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { __ } = this.props;

    return (
      <Loading>
        <IonContent className="rewards-content">
          <div className="absolute-content rewards-background"></div>
          <div className="rewards-container">My Rewards</div>
        </IonContent>
      </Loading>
    );
  }
}

export default connect(null, null)(withTranslation(Rewards));
