import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonBadge,
  IonButton,
} from '@ionic/react';
import Layout from '../../components/layout';
import PromoBar from '../../components/promoBar';
import { withTranslation } from '../../lib/translate';
import { isDefined, isEmptyObject, forwardTo } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import './index.css';
import { NormalText } from '../../components/common';

const { hasOrdering, hasLoyalty, hasCampaignManager } = getConfig().appType;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { __, screenName, vouchers } = this.props;
    return (
      <Layout hideSecondToolbar={true} color="transparent" noPadding={true}>
        <div className="absolute-content dash-layout">
          <IonButton
            className="external-btn"
            color="primary"
            onClick={() => window.open(getConfig().general.redirectURL, 'location=yes')}
          >
            <NormalText>
              {__('This app is no longer in use - please continue here on your browser')}
            </NormalText>
          </IonButton>
        </div>
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { profile, vouchers } = state.profile;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name}  ${profile.last_name}`;
  }
  return {
    screenName,
    vouchers: vouchers || [],
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
