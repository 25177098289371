import Dashboard from './screens/dashboard';
// import Loyalty from './screens/newLoyalty';
import Loyalty from './screens/loyalty';
import Rewards from './screens/rewards';
import Vouchers from './screens/vouchers';
import Account from './screens/account';
import ReferAFriend from './screens/referAFriend';
import Feedback from './screens/feedback';
import History from './screens/history';
import HistoryDetails from './screens/historyDetails';
import Locations from './screens/locations';
import Social from './screens/social';
import Login from './screens/login';
import Register from './screens/register';
import ResetPassword from './screens/resetPassword';
import Terms from './screens/terms';
import Privacy from './screens/privacy';
import Faq from './screens/faq';
import Order from './screens/order';
import ItemDetails from './screens/itemDetails';
import ClickAndCollect from './screens/clickAndCollect';
import Cards from './screens/cards';
import CardAdd from './screens/cardAdd';
import OrderSummary from './screens/orderSummary';
import ApplyPoints from './screens/applyPoints';
import Checkout from './screens/checkout';
import OrderCompleted from './screens/orderCompleted';
import ContactDetails from './screens/contactDetails';
import ApplyVouchers from './screens/applyVouchers';
import { getConfig } from './appConfig';

const home = require('./assets/images/home-icon.svg');
const loyalty = require('./assets/images/loyalty-icon.svg');
const myAccount = require('./assets/images/myAccount-icon.svg');
const feedback = require('./assets/images/feedback-icon.svg');
const history = require('./assets/images/history-icon.svg');
const restaurants = require('./assets/images/location-icon.svg');
const social = require('./assets/images/social-icon.svg');
const login = require('./assets/images/login-icon.svg');
const logout = require('./assets/images/logout-icon.svg');
const settings = require('./assets/images/settings-icon.svg');
const referAFriend = require('./assets/images/refer-friend-icon.svg');
// const vocuher = require('./assets/images/voucher-icon.svg');
const reward = require('./assets/images/reward-icon.svg');

const { hasCampaignManager, hasOrdering, hasLoyalty } = getConfig().appType;

const navConfig = {
  routes: [
    {
      label: 'Home',
      path: '/dashboard',
      component: Dashboard,
      icon: home,
      exact: true,
      default: true,
    },
    // ...(hasLoyalty
    //   ? [
    //       {
    //         label: 'Loyalty',
    //         path: '/loyalty',
    //         component: Loyalty,
    //         icon: loyalty,
    //         protected: true,
    //       },
    //     ]
    //   : []),
    // // ...(hasCampaignManager
    // //   ? [
    // //       {
    // //         label: 'Vouchers',
    // //         path: '/vouchers',
    // //         component: Vouchers,
    // //         icon: vocuher,
    // //         protected: true,
    // //       },
    // //     ]
    // //   : []),
    // ...(hasLoyalty
    //   ? [
    //       {
    //         label: 'My Rewards',
    //         path: '/rewards',
    //         component: Vouchers,
    //         icon: reward,
    //         protected: true,
    //       },
    //     ]
    //   : []),
    // ...(hasOrdering
    //   ? [
    //       {
    //         label: 'Click & Collect',
    //         path: '/click-and-collect',
    //         component: ClickAndCollect,
    //         icon: settings,
    //         protected: false,
    //       },
    //     ]
    //   : []),
    // ...(hasOrdering
    //   ? [
    //       {
    //         label: 'Checkout',
    //         path: '/checkout',
    //         component: Checkout,
    //         icon: settings,
    //         notInDrawer: true,
    //         protected: true,
    //       },
    //     ]
    //   : []),
    // ...(hasOrdering
    //   ? [
    //       {
    //         label: 'Apply Points',
    //         path: '/apply-points',
    //         component: ApplyPoints,
    //         icon: settings,
    //         notInDrawer: true,
    //         protected: true,
    //       },
    //     ]
    //   : []),
    // { label: 'My Account', path: '/account', component: Account, icon: myAccount, protected: true },
    // ...(hasCampaignManager
    //   ? [
    //       {
    //         label: 'Refer a Friend',
    //         path: '/refer-a-friend',
    //         component: ReferAFriend,
    //         icon: referAFriend,
    //         protected: true,
    //       },
    //     ]
    //   : []),
    // {
    //   label: 'Leave Feedback',
    //   path: '/feedback',
    //   component: Feedback,
    //   icon: feedback,
    //   protected: true,
    // },
    // ...(hasOrdering
    //   ? [{ label: 'History', path: '/history', component: History, icon: history, protected: true }]
    //   : []),
    // {
    //   label: 'Locations',
    //   path: '/locations',
    //   component: Locations,
    //   icon: restaurants,
    //   protected: false,
    // },
    // { label: 'Social Media', path: '/social', component: Social, icon: social, protected: false },
    // {
    //   label: 'Contact Details',
    //   path: '/contact-details',
    //   component: ContactDetails,
    //   protected: true,
    //   notInDrawer: true,
    // },
    // {
    //   label: 'Apply Vouchers',
    //   path: '/apply-vouchers',
    //   component: ApplyVouchers,
    //   notInDrawer: true,
    //   protected: true,
    // },
  ],
  authRoutes: [
    //   { label: 'Login', path: '/login', component: Login, icon: logout, fn: 'login' },
    //   { label: 'Logout', path: '/logout', icon: login, fn: 'logout' },
    //   { path: '/order-completed', component: OrderCompleted },
    //   { path: '/contact-details', component: ContactDetails },
    //   { path: '/loyalty', component: Loyalty },
  ],
  additionalRoutes: [
    //   { label: 'Terms & Conditions', path: '/terms', component: Terms },
    //   { label: 'Privacy Policy', path: '/privacy', component: Privacy },
    //   { label: 'Faq & Support', path: '/faq', component: Faq },
  ],
  notInMenuRoutes: [
    //   { path: '/register', component: Register },
    //   { path: '/reset-password', component: ResetPassword },
    //   ...(hasOrdering ? [{ path: '/item-details', component: ItemDetails }] : []),
    //   ...(hasOrdering ? [{ path: '/order', component: Order }] : []),
    //   ...(hasOrdering ? [{ path: '/cards', component: Cards }] : []),
    //   ...(hasOrdering ? [{ path: '/card-add', component: CardAdd }] : []),
    //   ...(hasOrdering ? [{ path: '/history-details', component: HistoryDetails }] : []),
    //   ...(hasOrdering ? [{ path: '/order-summary', component: OrderSummary }] : []),
    //   ...(hasOrdering ? [{ path: '/order-completed', component: OrderCompleted }] : []),
  ],
};

export default navConfig;
